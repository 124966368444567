.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content h1 {
  margin: 0px;
  margin-bottom: 10px;
}

.content-body-text {
  margin: 8px 0;
  font-size: calc(10px + 0.8vmin);
}

.contact {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-content: space-between; */
  align-items: center;
  justify-content: space-around;
}

.content-body-h3 {
  margin-top: 40px;
  margin-bottom: 0px;
  font-size: 0.7em;
}

.social-item {
  text-transform: lowercase;
  font-size: calc(10px + 0.8vmin);
  border-bottom: 1px pink solid;
  padding-bottom: 10px;
}

a:visited {
  color: white;
}

a {
  color: white;
  text-decoration: none;
}